/* .banner { */
 
   /* box-shadow: 5px 5px 5px 5px #dbdbdb;
   background-color: rgb(255, 255, 255);
   border-radius: 9px;
   opacity: 0;
   animation: fadeIn 1s ease-in-out 1s forwards; */

  
/* } */

/* @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  } */

  .banner {
    border:1px solid red;
  }

@media (max-width:420px) {
    .CookieConsent{
        border: 1px solid rgb(174, 31, 31);
    }
}