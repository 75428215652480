.second_page_container img{
    max-width: 95%;
   
}

.image_container{
    margin-top: 40px;
    display: flex;
    flex-direction: column ;
    object-fit: contain;
    transition: all 0.5s ease;
}

.second_page_container a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    
}

.image_container p{
    position: absolute;
    font-family: 'Montserrat';
font-weight:  600;
font-size: 15px;
  opacity: 0;
    margin-top: 8px;
    margin-left: 8px;
 }

 .image_container:hover p{
    opacity: 1;
  z-index: 1;
   
}
@media (max-width: 376px){
  
      .second_page_container img{
       
        max-width: 100%;
        margin-left: 0px;
        margin-bottom: 0px;
    }

.second_page_container a {
    text-decoration: none;
  
    
}
.second_page_container{

  width: 376px;
}

.image_container p{
    position: absolute;
    font-family: 'Montserrat';
font-weight:  600;
font-size: 15px;
  opacity: 1;
    margin-top: 8px;
    margin-left: 20px;
 }

 .image_container:hover p{
    opacity: 1;
  z-index: 1;
   
}

.image_container{
  margin-left: 15px;
  max-width: 375px;
  margin-top: 50px;
  display:block;

}
}

@media(min-width:377px) and (max-width: 420px){
  
  .second_page_container img{
    width: 410px;
    margin-left: 0px;
    margin-bottom: 0px;
}

.second_page_container a {
text-decoration: none;
}

.image_container p{
position: absolute;
font-family: 'Montserrat';
font-weight:  600;
font-size: 15px;
opacity: 1;
margin-top: 8px;
margin-left: 10px;
}

.image_container:hover p{
opacity: 1;
z-index: 1;

}

.image_container{
  margin-left: 10px;
width: 410px;
margin-top: 50px;
display:block;
}
}

/* @media (min-width:421px) and (max-width: 737px) {
  .second_page_container img{
    width: 650px;
    margin-left: 0px;
    margin-bottom: 0px;
}

.second_page_container a {
text-decoration: none;
}

.image_container p{
position: absolute;
font-family: 'Montserrat';
font-weight:  600;
font-size: 15px;
opacity: 1;
margin-top: 8px;
margin-left: 10px;
}

.image_container:hover p{
opacity: 1;
z-index: 1;

}

.image_container{
  margin-left: 10px;
width: 410px;
margin-top: 50px;
display:block;
}

} */