.res_grid {
    margin-top: 50px;
    margin-left: 20px;
       columns:3;
    margin-bottom: 50px;
}
@media(max-width: 376px){
 
   .res_grid{
      margin-top: 20px;
     margin-left: 0px;
        columns:1;
        margin-bottom: 50px;
   }
   }

   @media (min-width:377px) and (max-width: 420px){
      .res_grid{
        width: 410px;
        margin-top: 0px;
        margin-left: 0px;
           columns:1;
           margin-bottom: 50px;
      }
    
    }