
 @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');


* {
 

   margin: 0;
  padding: 0;
 
  
}


body {
  max-width: 1920px;
  min-height: 100vh;
 /* display: flex; */
 /* flex-direction: column; */
}
@media (max-width: 376px){
 body { max-width: 376px;}

}

@media (min-width:377px) and (max-width: 420px){}

@media (min-width:421px) and (max-width: 765px){}