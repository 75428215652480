.arch_pic_array {
   margin-top: 50px;
   margin-left: 20px;
   columns: 3;
   margin-bottom: 50px;
   }


   @media(max-width: 376px){
    .arch_pic_array{
  
      margin-top: 20px;
      margin-left: 0px;
         columns:1;
         margin-bottom: 50px;

    }
   
   }

   @media (min-width:377px) and (max-width: 420px){
    .arch_pic_array{
     
      width: 420px;
      margin-top: 20px;
      margin-left: 10px;
         columns:1;
         margin-bottom: 50px;
    }
  
  }
  