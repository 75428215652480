.cookies_container {
    height: 700px;
    width: 1100px;
   display: flex;
   flex-direction: column;
   margin: 100px auto;
  gap:20px;
}

.privacy_title{
    font-size: 20px;
    font-family: Lato;
}

.privacy_descr{
    font-size: 15px;
    font-family: Lato;
}

@media (max-width:420px) {
.cookies_container {
    width: 400px;
    height: 900px;
       ;
    }
}